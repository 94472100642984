import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import OffersCall from "../../Data/OffersCall";
import dayjs from "dayjs";
import { createDateFormate } from "../../constant/currentDateFunction";
import ResponsiveDatePickers from "../ResponsiveDatePickers";
import Swal from "sweetalert2";

export default function OffersDateRange({
  countryObject,
  fetchType,
  slice,
  setUseFilter,
  metadata,
  setSliceStartForFilter,
  setSlice,
  setFilteredTopics,
}) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleFromDate = (date) => {
    const dateTime = new Date(String(date)).getTime();
    const dateString = dayjs(dateTime).format("YYYY-MM-DD");
    setStartDate(dateString);
  };

  const handleToDate = (date) => {
    const dateTime = new Date(String(date)).getTime();
    const dateString = dayjs(dateTime).format("YYYY-MM-DD");
    setEndDate(dateString);
  };

  const handleDateSearch = async () => {
    if (startDate !== "" && endDate !== "") {
      Swal.fire({
        title: "تحديث العروض",
        html: "<h6>انتظر قليلاً يتم تحديث العروض</h6>",
        timer: 1200,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      setUseFilter(true);

      const apiCall = await OffersCall(
        "close",
        countryObject.code,
        fetchType,
        1,
        slice
      );
      const apiCallTopics = apiCall.topics;
      const topicsFiltration = apiCallTopics.filter((item) => {
        let itemDate = createDateFormate(item.start_at, "en");
        return itemDate >= startDate && itemDate <= endDate;
      });
      const sortTopicsFiltration = topicsFiltration.sort(
        (a, b) => a.start_at - b.start_at
      );
      const roundedNumber = Math.ceil(sortTopicsFiltration.length / 10);
      metadata.totalPages = roundedNumber;

      setFilteredTopics(sortTopicsFiltration);
    } else {
      Swal.fire({
        icon: "info",
        html: "<h3>حدد الفترة الزمنية اولاً</h3>",
      });
    }
  };

  const handleDateReset = () => {
    Swal.fire({
      title: "تحديث العروض",
      html: "<h6>انتظر قليلاً يتم تحديث العروض</h6>",
      timer: 700,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setSlice(10);
    setSliceStartForFilter(0);
    setUseFilter(false);
  };

  return (
    <Card>
      <Row dir="rtl">
        <Col md={4}>
          <Card.Body>
            <div className="row align-items-center justify-content-center">
              <div className="col-auto">
                {/* <i className={`${data.icon} f-36`} /> */}
              </div>
              <div className="col text-end">
                <h5>من تاريخ :</h5>
                <h5 className="text-c-green mb-0">
                  <ResponsiveDatePickers onClick={handleFromDate} />
                </h5>
              </div>
            </div>
          </Card.Body>
        </Col>
        <Col md={4}>
          <Card.Body>
            <div className="row align-items-center justify-content-center">
              <div className="col-auto">
                {/* <i className={`${data.icon} f-36`} /> */}
              </div>
              <div className="col text-end">
                <h5>الى تاريخ :</h5>
                <h5 className="text-c-green mb-0">
                  <ResponsiveDatePickers onClick={handleToDate} />
                </h5>
              </div>
            </div>
          </Card.Body>
        </Col>
        <Col style={{ paddingTop: "1.3%" }} md={4}>
          <Card.Body>
            <Row>
              <Col md={5}>
                <Button
                  variant={"secondary"}
                  className="btn-square text-capitalize"
                  onClick={handleDateSearch}
                  style={{ fontSize: "100%" }}
                >
                  إبحث
                </Button>
              </Col>
              <Col md={7}>
                <Button
                  variant={"info"}
                  className="btn-square text-capitalize"
                  onClick={handleDateReset}
                  style={{ fontSize: "100%" }}
                >
                  إعادة ضبط
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}
