import { headers, posting_api } from "../constant/constant";

const getFromTable = async (tableName) => {
  const url = `${posting_api}get-from-table`;

  const body = { tableName };
  const requestInfo = {
    method: "POST",
    headers,
    body: JSON.stringify(body), // Convert postData to JSON string
  };

  try {
    const response = await fetch(url, requestInfo);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const res = await response.text();
    return { status: true, text: res };
  } catch (error) {
    return {
      status: false,
      text: error,
    };
  }
};

export default getFromTable;
