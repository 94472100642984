import "./App.scss";
import "./assets/Login.css";
import Home from "./Screens/Home";
import Navbar from "./component/Navbar.jsx";
import RoutingContext from "./constant/RoutingContext.js";
import { useEffect, useState } from "react";
import ScreenWidth from "./constant/ScreenWidth.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Posting } from "./Screens/Posting.jsx";
import { Accounts } from "./Screens/Accounts.jsx";
import Login from "./Screens/Authentication/Login.jsx";
import AuthorisationContext from "./constant/AuthorisationContext.js";
import TwiterIdsProvider from "./constant/TwiterIdsProvider.js";
import PostedOffer from "./Screens/PostedOffer.jsx";
import UserContext from "./constant/UserContext.js";
import Settings from "./Screens/Settings/Settings.jsx";

function App() {
  const [currentRoute, setRoutIndex] = useState(0);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [userAuthorised, setAuthorisationStatus] = useState(auth || false);
  const [pages_ids, setPagesIds] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    setCurrentUser(user);
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setAuthorisationStatus(auth);
  }, [auth]);

  return (
    <RoutingContext.Provider value={{ currentRoute, setRoutIndex }}>
      <UserContext.Provider value={{ currentUser, setCurrentUser }}>
        <ScreenWidth.Provider value={{ screenSize, setScreenSize }}>
          <AuthorisationContext.Provider
            value={{ userAuthorised, setAuthorisationStatus }}
          >
            <TwiterIdsProvider.Provider value={{ pages_ids, setPagesIds }}>
              <div className="App background-radial-gradient ">
                <Router>
                  <Routes>
                    <Route
                      exact
                      path={"/login"}
                      element={
                        <div className="homeScreen">
                          <Login />
                        </div>
                      }
                    />

                    <Route
                      exact
                      path={"/"}
                      element={
                        userAuthorised ? (
                          <>
                            <Navbar />

                            <div className="homeScreen">
                              <Home />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/login" replace />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/posting"
                      element={
                        userAuthorised ? (
                          <>
                            <Navbar />
                            <div className="homeScreen">
                              <Posting />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/login" replace />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/posted-offer"
                      element={
                        userAuthorised ? (
                          <>
                            <Navbar />
                            <div className="homeScreen">
                              <PostedOffer />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/login" replace />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/settings"
                      element={
                        userAuthorised ? (
                          <>
                            <Navbar />
                            <div className="homeScreen">
                              <Settings />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/login" replace />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/accounts"
                      element={
                        userAuthorised ? (
                          <>
                            <Navbar />
                            <div className="homeScreen">
                              <Accounts />
                            </div>
                          </>
                        ) : (
                          <Navigate to="/login" replace />
                        )
                      }
                    />
                  </Routes>
                </Router>
              </div>
            </TwiterIdsProvider.Provider>
          </AuthorisationContext.Provider>
        </ScreenWidth.Provider>
      </UserContext.Provider>
    </RoutingContext.Provider>
  );
}

export default App;
