import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import { headers, posting_api } from "../constant/constant";

// Step 1: Extend the custom hook to handle multiple API calls
const useApiReducer = () => {
  const initialState = {
    social_pages: { data: null, isLoading: false, error: null },
    users: { data: null, isLoading: false, error: null },
    posted_offers: { data: null, isLoading: false, error: null },
    // Add more API states as needed
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_INIT":
        return {
          ...state,
          [action.apiName]: {
            ...state[action.apiName],
            isLoading: true,
            error: null,
          },
        };
      case "FETCH_SUCCESS":
        return {
          ...state,
          [action.apiName]: {
            ...state[action.apiName],
            isLoading: false,
            data: action.payload,
          },
        };
      case "FETCH_FAILURE":
        return {
          ...state,
          [action.apiName]: {
            ...state[action.apiName],
            isLoading: false,
            error: action.payload,
          },
        };
      default:
        return state;
    }
  };

  return useReducer(reducer, initialState);
};

// Step 2: Update the context provider to manage multiple API states
const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [state, dispatch] = useApiReducer();
  const [fetchTrigger, setFetchTrigger] = useState(true);

  // Modify useEffect to handle multiple API calls
  useEffect(() => {
    const fetchData = async (apiName, endPoint) => {
      dispatch({ type: "FETCH_INIT", apiName });
      try {
        const response = await fetch(posting_api + endPoint, {
          method: "GET",
          headers,
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        dispatch({ type: "FETCH_SUCCESS", apiName, payload: data });
      } catch (error) {
        dispatch({ type: "FETCH_FAILURE", apiName, payload: error.message });
      }
    };

    fetchData("social_pages", "get-social-pages");
    fetchData("users", "get-users");
    fetchData("posted_offers", "get-posted-offers");
  }, [fetchTrigger, dispatch]);

  const triggerRefetch = () => {
    setFetchTrigger(!fetchTrigger);
  };

  return (
    <ApiContext.Provider value={{ state, dispatch, triggerRefetch }}>
      {children}
    </ApiContext.Provider>
  );
};

// Step 3: Use the context in components
export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};
