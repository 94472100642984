import React, { useContext, useEffect, useState } from "react";
import "../../assets/Login.css";
import {
  MDBBtn,
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import ScreenWidth from "../../constant/ScreenWidth";
import loginCall from "../../Data/loginCall";
import AuthorisationContext from "../../constant/AuthorisationContext";
import { useNavigate } from "react-router-dom";
import UserContext from "../../constant/UserContext";
import { useApi } from "../../provider/ApiProvider";
import Swal from "sweetalert2";
import updateTables from "../../Data/updateTables";
import currentDateFunction from "../../constant/currentDateFunction";

function Login() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { screenSize } = useContext(ScreenWidth);
  const { userAuthorised, setAuthorisationStatus } =
    useContext(AuthorisationContext);
  const { setCurrentUser } = useContext(UserContext);
  const { state } = useApi();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));

    if (auth) {
      Swal.fire({
        title: "مراجعة البيانات",
        html: "<h6>انتظر قليلاً للتأكد من هويتك</h4>",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      Swal.fire({
        icon: "success",
        title: "نجح الاتصال",
        text: "سوف يتم تحويل الان",
        timer: 1000,
        didOpen: () => {
          Swal.showLoading();
        },
      }).then(() => {
        navigate("/");
      });
    }
  }, [userAuthorised]);

  const handleLogin = async () => {
    Swal.fire({
      title: "مراجعة البيانات",
      html: "<h6>انتظر قليلاً للتأكد من هويتك</h6>",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const authorised = await loginCall(username, password);
      setAuthorisationStatus(authorised);

      if (authorised) {
        const users = await state.users.data;
        const user = users.find((user) => user.username === username);
        user.last_login_date = currentDateFunction().dateFormat;
        user.last_login_time =
          currentDateFunction().dayName +
          " " +
          currentDateFunction().timeFormat;
        const fieldsToUpdate = {
          last_login_date: currentDateFunction().dateFormat,
          last_login_time:
            currentDateFunction().dayName +
            " " +
            currentDateFunction().timeFormat,
        };

        if (user) {
          setCurrentUser(user);
          localStorage.setItem("currentUser", JSON.stringify(user));
          const respinse = await updateTables(
            "users",
            fieldsToUpdate,
            `user_id = ${user.user_id}`
          );
          if (respinse.status) {
            Swal.fire({
              icon: "success",
              title: "نجح الاتصال",
              text: "سوف يتم تحويل الان",
              timer: 1000,
              didOpen: () => {
                Swal.showLoading();
              },
            }).then(() => {
              localStorage.setItem("auth", JSON.stringify(authorised));
              navigate("/");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "خطاء",
              text: respinse.text,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "خطاء",
            text: "تأكد من البيانات واعد المحاولة",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "خطاء",
          text: "تأكد من البيانات واعد المحاولة",
        });
        localStorage.setItem("auth", JSON.stringify(authorised));
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire({
        icon: "error",
        title: "خطاء",
        text: "حدث خطأ أثناء عملية تسجيل الدخول",
      });
    }
  };

  return (
    <MDBContainer
      fluid
      className="d-flex align-items-center justify-content-center overflow-hidden overflow-hidden"
    >
      <MDBCol md={screenSize > 766 ? 5 : 3} className="position-relative">
        <MDBCard className="my-5 bg-glass">
          <div className="d-flex flex-row align-items-center justify-content-center mt-5">
            <span className="h1 fw-bold mb-0">ترحيل عروض تسوق</span>
          </div>
          <MDBCardBody className="p-5">
            <MDBInput
              dir="rtl"
              wrapperClass="mb-4"
              label="اسم المستخدم"
              id="form3"
              type="text"
              onChange={(e) => {
                setUsername(e.target.value.toLowerCase());
              }}
            />
            <MDBInput
              dir="rtl"
              wrapperClass="mb-4"
              label="كلمة السر"
              id="form4"
              type={checked ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <div dir="rtl" className="d-flex  justify-content-flex-end mb-4">
              <MDBCheckbox
                name="flexCheck"
                checked={checked}
                onChange={() => setChecked(!checked)}
                id="flexCheckDefault"
                // label="عرض كلمة المرور"
              />
              <p
                style={{ height: "10px", marginRight: "2%", fontSize: "15px" }}
              >
                عرض كلمة المرور
              </p>
            </div>

            <MDBBtn
              style={{ fontSize: "20px" }}
              className="w-100 mb-4"
              size="lg"
              onClick={handleLogin}
            >
              تسجيل الدخول
            </MDBBtn>
            {/* <a style={{ fontSize: "18px", fontWeight: "bold" }} href="/login">
              ! لا اتذكر كلمة السر
            </a> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBContainer>
  );
}

export default Login;
