import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import { tweetObject } from "../constant/postingConstant";
import CustomPagination from "../component/CoustmPagination";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../component/Loader";
import { useApi } from "../provider/ApiProvider";
import Swal from "sweetalert2";
import tweetsAPI from "../Data/tweetsAPI";
import UserContext from "../constant/UserContext";
import { reformUrl } from "../constant/constant";
import { toArabicDigits } from "../constant/currentDateFunction";

export const Posting = () => {
  //Configration
  const [selectedPageInList, setSelectedPageInList] = useState(0);
  const [useUrl, setUseURL] = useState(true);
  const [useImages, setUseImages] = useState(true);
  const [sendToFacebook, setSendToFacebook] = useState(false);
  const [sendToTwitter, setSendToTwitter] = useState(true);
  const [onSubmit, setOnSubmit] = useState(false);
  const navigate = useNavigate();
  //Socila Pages
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(true);
  const [selectedPages, setSelectedPages] = useState([]);
  const [pageStateCanged, setPageStateCanged] = useState(false);
  const { state } = useApi();
  let pageSelector = selectedPages;
  const social_pages = state.social_pages.data || [];
  const { currentUser } = useContext(UserContext);

  const handelChoosedPage = (e, id) => {
    const pageId = id ? id : e.target.value;
    setSelectedPageInList(pageId);
    if (pageId !== 0) {
      if (!pageSelector.includes(pageId)) pageSelector.push(pageId);
      setSelectedPages(pageSelector);
      setPageStateCanged(!pageStateCanged);
    }
  };

  const removeSelectedPage = (item) => {
    const pageId = item.page_id;
    setSelectedPageInList(0);
    if (pageSelector.includes(JSON.stringify(pageId))) {
      // eslint-disable-next-line
      pageSelector = pageSelector.filter((id) => id != pageId);
    }
    setSelectedPages(pageSelector);
    setPageStateCanged(!pageStateCanged);
  };

  //offer
  const [selectedOffers, setSelectedOffers] = useState([]);
  const currentOffer = selectedOffers[pageNumber - 1];
  const location = useLocation();
  const { data, offers } = location.state || { data: [], offers: [] };
  const metadata = {
    totalPages: data ? selectedOffers.length : 0,
  };
  useEffect(() => {
    setSendToFacebook(false);
    const startFilter = async () => {
      if (offers && data) {
        // Ensure offers and data exist
        if (!onSubmit) {
          const selected = await offers.filter((item) =>
            data.includes(item.id)
          );
          setSelectedOffers(selected);
        }
      }
    };

    startFilter();
  }, [offers, data, onSubmit]);

  useEffect(() => {
    if (onSubmit) {
      if (selectedOffers.length === 0) {
        navigate("/");
      }
    }
  }, [selectedOffers, onSubmit, navigate]);

  //Text
  const [arTitle, setARTitle] = useState("");
  const [enTitle, setENTitle] = useState("");
  const [textArea, setTextArea] = useState("");
  const [offerURL, setOfferURL] = useState("");
  const [delay, setDelay] = useState("");

  useEffect(() => {
    if (currentOffer) {
      setARTitle(currentOffer.title);
      setENTitle(currentOffer.translated_title);
      const meURL = reformUrl(currentOffer.meOfferUrl);
      setOfferURL(meURL);
      //Loader
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [currentOffer]);

  const arabicTitleChange = (e) => {
    const text = e.target.value;
    setARTitle(text);
  };
  const engilshTitleChange = (e) => {
    const text = e.target.value;
    setENTitle(text);
  };

  const textareaChange = (e) => {
    const text = e.target.value;
    setTextArea(text);
  };

  useEffect(() => {
    if (currentOffer) {
      social_pages.filter((page) => {
        if (page.page_country === currentOffer.country) {
          handelChoosedPage({ target: { value: 0 } }, page.page_id.toString());
        }
      });
    }
  }, [currentOffer]);

  const handleSubmit = async () => {
    if (selectedPages.length > 0) {
      setOnSubmit(true);
      pageSelector = [];
      setSelectedPages(pageSelector);
      setPageStateCanged(!pageStateCanged);
      setSelectedPageInList(0);

      tweetObject.offer_image = currentOffer.external_image;
      tweetObject.offer_title = currentOffer.title;
      tweetObject.userId = currentUser.user_id;
      tweetObject.message = `${`${textArea}\n${arTitle},\n${enTitle}\n${
        useUrl
          ? currentOffer.links.length > 4
            ? `شاهد ${toArabicDigits(
                currentOffer.links.length - 4
              )} عرض اضافي من خلال الرابط`
            : ""
          : ""
      }`}`;

      if (useUrl) {
        tweetObject.link = offerURL;
      }
      // currentOffer.links.length <= 4 ? "" :

      tweetObject.imageURL = useImages ? currentOffer.links : false;

      async function processPages() {
        for (const item of selectedPages) {
          const tweetObjectCopy = { ...tweetObject }; // Create a copy of the tweetObject for each iteration
          tweetObjectCopy.pageId = item;
          tweetObjectCopy.offerId = currentOffer.id;

          if (delay !== 0 && typeof delay === "number")
            tweetObjectCopy.delay = delay;

          const res = await tweetsAPI(JSON.stringify(tweetObjectCopy));

          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "نجح الاتصال",
              html: `<h3>${res.text}</h3>`,
              timer: 1200,
              didOpen: () => {
                Swal.showLoading();
              },
            }).then(() => {
              const updatedSelectedOffers = selectedOffers.filter(
                (offer) => offer.id !== currentOffer.id
              );
              setSelectedOffers(updatedSelectedOffers);
            });
          } else {
            Swal.fire({
              title: "!خطأ",
              text: res.text,
              icon: "error",
              confirmButtonText: "حسناً",
            });
          }
        }
      }
      await processPages();
    } else {
      Swal.fire({
        title: "!خطأ",
        text: "قم باختيار صفحة واحده على الاقل",
        icon: "error",
        confirmButtonText: "حسناً",
      });
    }
  };

  return (
    <Row>
      {!loader ? (
        <Col sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">نشر الموضوع</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form style={{ fontSize: "23px" }}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>العنوان عربي</Form.Label>
                      <Form.Control
                        style={{ fontSize: "20px" }}
                        value={arTitle}
                        onChange={arabicTitleChange}
                        type="text"
                        placeholder="عنوان العرض"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>العنوان انجليزي</Form.Label>
                      <Form.Control
                        style={{ fontSize: "20px" }}
                        value={enTitle}
                        onChange={engilshTitleChange}
                        type="text"
                        placeholder="عنوان العرض"
                      />
                    </Form.Group>
                    {useUrl && (
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>رابط العرض</Form.Label>
                        <InputGroup className="mb-3">
                          <FormControl
                            value={offerURL}
                            onChange={() => {}}
                            aria-describedby="basic-addon1"
                          />
                          <Button
                            onClick={() => {
                              const meURL = reformUrl(currentOffer.meOfferUrl);
                              setOfferURL(meURL);
                            }}
                            variant={
                              offerURL.includes("net") ? "secondary" : "primary"
                            }
                          >
                            me
                          </Button>
                          <Button
                            onClick={() => {
                              setOfferURL(currentOffer.netOfferUrl);
                            }}
                            variant={
                              offerURL.includes("net") ? "primary" : "secondary"
                            }
                          >
                            net
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    )}

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>محتوى العرض </Form.Label>
                      <Form.Control
                        onChange={textareaChange}
                        as="textarea"
                        rows="3"
                      />
                    </Form.Group>
                    <Form.Group>
                      <ListGroup>
                        <Card
                          style={{
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                        >
                          <Form.Control
                            as="select"
                            className="mb-3"
                            value={selectedPageInList}
                            onChange={(e) => handelChoosedPage(e)}
                          >
                            <option value={0}>Default select</option>
                            {social_pages.map((page) => (
                              <option key={page.page_id} value={page.page_id}>
                                {page.page_name}
                              </option>
                            ))}
                          </Form.Control>
                          <div className="mb-2">
                            <Table>
                              <tbody>
                                {selectedPages.map((id) => {
                                  return social_pages.map((item) => {
                                    // eslint-disable-next-line
                                    if (item.page_id == id) {
                                      return (
                                        <tr
                                          key={item.page_id}
                                          style={{ height: "30px" }}
                                        >
                                          <td>{item.page_name}</td>
                                          <td>
                                            <Button
                                              className="btn btn-danger"
                                              onClick={() =>
                                                removeSelectedPage(item)
                                              }
                                            >
                                              Remove
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                    return true;
                                  });
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </Card>
                      </ListGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <img
                        style={{ width: "60%", borderRadius: 20 }}
                        src={currentOffer ? currentOffer.external_image : ""}
                        alt="Offer"
                      />
                    </Form.Group>
                    <br />

                    <Form.Group
                      className=" d-flex  justify-content-end m-10"
                      controlId="formBasicChecbox"
                    >
                      <Form.Check
                        style={{ fontSize: "1.3rem" }}
                        className="d-inline-block"
                        type="checkbox"
                        label="استخدام الرابط فقط"
                        checked={useUrl}
                        onChange={() => {}}
                        onClick={() => {
                          setUseURL(!useUrl);
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className=" d-flex  justify-content-end  m-10"
                      controlId="formBasicChecbox"
                    >
                      <Form.Check
                        style={{ fontSize: "1.3rem" }}
                        className="d-inline-block"
                        type="checkbox"
                        checked={useImages}
                        onChange={() => {}}
                        onClick={() => {
                          setUseImages(!useImages);
                        }}
                        label={`${
                          currentOffer ? currentOffer.links.length : ""
                        } : استخدام الصور فقط - عدد الصور`}
                      />
                    </Form.Group>
                    <Form.Group
                      className=" d-flex  justify-content-end  m-10"
                      controlId="formBasicChecbox"
                    >
                      <Form.Check
                        style={{ fontSize: "1.3rem" }}
                        className="d-inline-block"
                        type="checkbox"
                        label="استخدام الصور و الرابط"
                        checked={useUrl && useImages ? true : false}
                        onChange={() => {}}
                        onClick={() => {
                          if (
                            (useUrl && useImages) ||
                            (!useUrl && !useImages)
                          ) {
                            setUseImages(!useImages);
                            setUseURL(!useUrl);
                          } else if (!useUrl && useImages) {
                            setUseURL(!useUrl);
                          } else if (useUrl && !useImages) {
                            setUseImages(!useImages);
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className=" d-flex  justify-content-end  m-10"
                      controlId="formBasicChecbox"
                    >
                      <Row>
                        <Col>
                          <Form.Check
                            style={{ fontSize: "1.3rem" }}
                            className="d-inline-block"
                            type="checkbox"
                            label="Twitter"
                            checked={sendToTwitter}
                            onChange={() => {}}
                            onClick={() => {
                              setSendToTwitter(!sendToTwitter);
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            style={{ fontSize: "1.3rem" }}
                            className="d-inline-block"
                            type="checkbox"
                            label="Facebook"
                            defaultChecked={sendToFacebook}
                            onChange={() => {}}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Row className="d-flex justify-content-end">
                        <Col md={2}>
                          <Form.Label>ساعات</Form.Label>
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            type="number"
                            min="0"
                            value={delay}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Validate if the entered value is a positive integer
                              if (
                                !isNaN(value) &&
                                Number.isInteger(parseFloat(value)) &&
                                parseInt(value) >= 0
                              ) {
                                const intValue = Number(value);
                                console.log(intValue);
                                setDelay(intValue);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Button
                      onClick={handleSubmit}
                      style={{ fontSize: "1.3rem" }}
                      variant="primary"
                    >
                      نشر المحتوى
                    </Button>
                  </Col>
                </Row>
                <Form.Group>
                  <br />
                  <div className="d-flex justify-content-center ">
                    <CustomPagination
                      metadata={metadata}
                      setPageNumber={setPageNumber}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      ) : (
        <div className="loaderContainer">
          <Loader />
        </div>
      )}
    </Row>
  );
};
