import axios from "axios";
import { headers, posting_api } from "../constant/constant";

const getDataWithEndPoint = async (endPoint) => {
  try {
    const response = await fetch(posting_api + endPoint, {
      method: "GET",
      headers,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error geting data from " + endPoint + " " + error);
  }
};

export default getDataWithEndPoint;
