import React, { useState } from "react";
import { Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import currentDateFunction from "../../constant/currentDateFunction";
import Swal from "sweetalert2";
import addNewUser from "../../Data/addNewUser";
import ChangeAppInfo from "./ChangeAppInfo";

const Settings = () => {
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("");

  const newUserObject = {
    timeCreated: currentDateFunction().dateFormat,
    user_role: userRole,
    email: email.toLowerCase(),
    password,
    username: username.toLowerCase(),
  };
  const handelSubmit = async () => {
    // eslint-disable-next-line
    if (userRole != 0 && password !== "" && username !== "") {
      if (password.length >= 6 && username.length >= 3) {
        const res = await addNewUser(newUserObject);
        const parsedRes = JSON.parse(res);

        if (parsedRes.success) {
          Swal.fire({
            icon: "success",
            title: "تم الاضافة ",
            text: `${userRole}:صلاحيات المسخدم  ،${username} تم اضافة `,
            timer: 1000,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          setPassword("");
          setEmail("");
          setUserRole("");
          setUsername("");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطاء",
            text: parsedRes.text,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "خطاء",
          text: "تأكد من ان اسم المستخدم لا يقل عم ٣ حروف وكلمة السر لا تقل عن ٦ حروف",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "خطاء",
        text: "تأكد من كل الخانات و البيانات واعد المحاولة",
      });
    }
  };

  return (
    <>
      <Card dir="rtl">
        <Card.Header>
          <Card.Title style={{ fontSize: "25px" }} as="h5">
            تسجيل مستخدم جديد
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  {/* <Form.Label>اسم المستخدم</Form.Label> */}
                  <h5>اسم المستخدم</h5>
                  <Form.Control
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    type="email"
                    placeholder="اسم المستخدم"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  {/* <Form.Label>كلمة السر</Form.Label> */}
                  <h5>كلمة السر</h5>
                  <InputGroup dir="ltr" className="mb-3">
                    <Form.Control
                      dir="rtl"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type={passwordState ? "text" : "password"}
                      placeholder="كلمة السر"
                    />

                    <Button onClick={() => setPasswordState(!passwordState)}>
                      {!passwordState ? "اظهار" : "اخفاء"}
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <h5>البريد الإلكتروني</h5>

                <Form.Control
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  placeholder="البريد الإلكتروني"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlSelect1"
              >
                <h5>الصلاحية</h5>
                <Form.Control
                  value={userRole}
                  onChange={(e) => {
                    setUserRole(e.target.value);
                  }}
                  as="select"
                >
                  <option value={0}>اختر صلاحية المستخدم</option>
                  <option value={"admin"}>admin</option>
                  <option value={"user"}>user</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button
            onClick={handelSubmit}
            style={{ fontSize: "20px" }}
            variant="primary"
          >
            سجل المستخدم
          </Button>
        </Card.Body>
      </Card>
      <ChangeAppInfo />
    </>
  );
};

export default Settings;
