import { headers, posting_api } from "../constant/constant";

const addToTable = async (tableName, conditionValue, condition, addObject) => {
  const url = `${posting_api}add-to-table`;

  const body = { tableName, conditionValue, condition, addObject };
  const requestInfo = {
    method: "POST",
    headers,
    body: JSON.stringify(body), // Convert postData to JSON string
  };

  try {
    const response = await fetch(url, requestInfo);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const res = await response.text();
    const parse = JSON.parse(res);

    return { status: parse.status, text: parse.text };
  } catch (error) {
    return {
      status: false,
      text: error,
    };
  }
};

export default addToTable;
