import React, { useContext, useEffect, useState } from "react";
import { Card, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import OffersCall from "../../Data/OffersCall";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import OffersTable from "./OffersTable";
import RoutingContext from "../../constant/RoutingContext";
import CoustmPagination from "../CoustmPagination";
import { countryArray } from "../../constant/constant";
import OffersDateRange from "./OffersDateRange";
import { Loader } from "../Loader";
import Swal from "sweetalert2";
import getDataWithEndPoint from "../../Data/getDataWithEndPoint";

export const Offers = () => {
  const { currentRoute } = useContext(RoutingContext);
  const [countryObject, setCountryObject] = useState({
    id: 0,
    country: "الكل",
    code: "all",
  });
  const [dataType, setDataType] = useState("net");
  const [clicked, setClicked] = useState(true);
  const [topics, setTopics] = useState([]);
  const [metadata, setMetadata] = useState({});

  const [slice, setSlice] = useState(10);
  const [sliceStartForFilter, setSliceStartForFilter] = useState(0);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [userFilter, setUseFilter] = useState(false);
  const pagState = currentRoute === 1 ? "open" : "close";
  const copyOfTopics = [...topics];
  const sortTopics = copyOfTopics.sort((a, b) => b.start_at - a.start_at);
  const sliceTopics = sortTopics.slice(0, slice);
  const fetchType = countryObject.code === "all" ? "net" : dataType;
  const mapingArray = userFilter
    ? filteredTopics.slice(sliceStartForFilter, slice)
    : sliceTopics || [];
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const offersArray = await OffersCall(
          pagState,
          countryObject.code,
          fetchType,
          pageNumber,
          slice
        );

        const fetchPosted = async () => {
          try {
            const fetchPosted = await getDataWithEndPoint("get-posted-offers");
            return fetchPosted;
          } catch (error) {
            Swal.fire({
              html: `<h3>خطأ استرجاع بيانات العروض المسجلة</h3>`,
            });
          }
        };

        const postedOffers = await fetchPosted();

        const mapedPosted = offersArray.topics.map((offerAPI) => {
          const matchedOffer = postedOffers.find((postedOffer) => {
            return postedOffer.offer_id === offerAPI.id;
          });

          if (matchedOffer) {
            // Add status property to the offerAPI object
            offerAPI.status = matchedOffer.status;
          }

          return offerAPI;
        });

        setTopics(mapedPosted);
        setMetadata(offersArray.metadata);
      } catch (error) {
        setTopics([]);
        setMetadata({
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          hasNextPage: false,
          hasPreviousPage: false,
        });
      }
    };

    if (!userFilter) {
      fetchData();
    }
  }, [
    slice,
    fetchType,
    countryObject,
    currentRoute,
    pagState,
    pageNumber,
    userFilter,
  ]);

  useEffect(() => {
    if (countryObject.code === "all") {
      setDataType("net");
      setClicked(true);
    } else {
      setDataType(clicked ? "net" : "me");
    }
  }, [clicked, countryObject]);

  useEffect(() => {
    if (userFilter) {
      const startIndex = (pageNumber - 1) * 10;
      const endIndex = pageNumber * 10;
      setSlice(endIndex);
      setSliceStartForFilter(startIndex);
    }
  }, [pageNumber, userFilter]);

  const handleCountrySelect = (selectedCountry) => {
    setCountryObject(selectedCountry);
    Swal.fire({
      title: "تحديث العروض",
      html: "<h6>انتظر قليلاً يتم تحديث العروض</h6>",
      timer: 1200,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const handleDataTypeSelect = () => {
    if (countryObject.code !== "all") {
      setClicked((prevClicked) => !prevClicked);
      Swal.fire({
        title: "تحديث العروض",
        html: "<h6>انتظر قليلاً يتم تحديث العروض</h6>",
        timer: 1200,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setSlice(value);
    }
  };

  useEffect(() => {
    if (mapingArray.length > 0) {
      setLoader(false);
    }
  }, [mapingArray]);

  useEffect(() => {
    if (currentRoute === 1) {
      setSlice(10);
    }
  }, [currentRoute]);

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      {currentRoute === 1 && (
        <OffersDateRange
          countryObject={countryObject}
          fetchType={fetchType}
          slice={slice}
          setUseFilter={setUseFilter}
          setFilteredTopics={setFilteredTopics}
          metadata={metadata}
          setSliceStartForFilter={setSliceStartForFilter}
          setSlice={setSlice}
        />
      )}
      <Card dir="rtl" className="Recent-Users">
        <Card.Header>
          <Row>
            <Col style={{ paddingBottom: "10px" }} md={4}>
              <Card.Title style={{ fontSize: "25px" }} as="h5">
                {currentRoute === 1 ? "كل العُرُوْض" : "احدث العُرُوْض"}
              </Card.Title>
            </Col>
            {currentRoute === 0 && (
              <Col md={3} dir="ltr">
                <MDBInput
                  label="عدد المعروض"
                  type="text"
                  value={slice}
                  onChange={handleInputChange}
                  pattern="\d*"
                />
              </Col>
            )}
            {currentRoute === 1 && (
              <Col>
                <DropdownButton
                  title={countryObject.country}
                  id="input-group-dropdown-1"
                >
                  {countryArray.map((item) => (
                    <Dropdown.Item
                      key={item.id}
                      onClick={() => handleCountrySelect(item)}
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {item.country}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            )}
            {currentRoute === 1 && (
              <Col>
                <MDBBtn
                  style={{ textAlign: "center", fontSize: "20px" }}
                  onClick={() => {
                    handleDataTypeSelect();
                  }}
                  color="light"
                  rippleColor="dark"
                >
                  {dataType}
                </MDBBtn>
              </Col>
            )}
          </Row>
        </Card.Header>
        <OffersTable
          mapingArray={mapingArray}
          metadata={metadata}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
        />

        <>
          {currentRoute === 1 && (
            <div className="d-flex justify-content-center ">
              <CoustmPagination
                metadata={metadata}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                userFilter={userFilter}
              />
            </div>
          )}
        </>
      </Card>
    </>
  );
};
