const currentDateFunction = (lang) => {
  const currentDate = new Date();
  const options = { weekday: "short" };
  const dayName = currentDate.toLocaleString("ar-SA", options);

  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const amPM = hours >= 12 ? "مساءً" : "صباحً";
  hours = hours % 12 || 12; // Convert to 12-hour format

  // Function to convert Western digits to Arabic-Indic digits

  const dateFormat =
    lang === "en"
      ? `${currentDate.getDate()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getFullYear()}`
      : `${toArabicDigits(currentDate.getDate())} - ${toArabicDigits(
          currentDate.getMonth() + 1
        )} - ${toArabicDigits(currentDate.getFullYear())}`;
  const timeFormat = `${toArabicDigits(hours)}:${
    minutes < 10 ? toArabicDigits("0" + minutes) : toArabicDigits(minutes)
  } ${amPM}`;

  return { dateFormat, timeFormat, dayName };
};

function toArabicDigits(number) {
  return String(number).replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d]);
}

const createDateFormate = (num, lang) => {
  const timestamp = num * 1000;
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate =
    lang === "en"
      ? `${year}-${month}-${day}`
      : `${toArabicDigits(year)}/${toArabicDigits(month)}/${toArabicDigits(
          day
        )}`;
  return formattedDate;
};

export { toArabicDigits, createDateFormate };
export default currentDateFunction;
