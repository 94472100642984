import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Table } from "react-bootstrap";
import ScreenWidth from "../constant/ScreenWidth";
import { Loader } from "../component/Loader";
import tweetsAPI from "../Data/tweetsAPI";
import Swal from "sweetalert2";
import deleteFromTable from "../Data/deleteFromTable";
import SwalStatusHandler from "../component/SwalStatusHandler";
import { getCurrentState, headers, posting_api } from "../constant/constant";
import getDataWithEndPoint from "../Data/getDataWithEndPoint";

const PostedOffer = () => {
  const [postedOffers, setPostedOffers] = useState([]);
  const [reload, setReload] = useState(false);
  const [postedOffer, setPostedOffer] = useState([]);
  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState([]);

  const [loader, setLoader] = useState(true);
  const { screenSize } = useContext(ScreenWidth);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchUsers = await getDataWithEndPoint("get-users");
        setUsers(fetchUsers);
      } catch (error) {
        Swal.fire({
          html: `<h3>خطأ استرجاع بيانات المستخدم</h3>`,
        });
      }
    };
    const fetchPages = async () => {
      try {
        const fetchPages = await getDataWithEndPoint("get-social-pages");
        setPages(fetchPages);
      } catch (error) {
        Swal.fire({
          html: `<h3>خطأ استرجاع بيانات الصفحات</h3>`,
        });
      }
    };
    const fetchPosted = async () => {
      try {
        const fetchPosted = await getDataWithEndPoint("get-posted-offers");
        setPostedOffer(fetchPosted);
      } catch (error) {
        Swal.fire({
          html: `<h3>خطأ استرجاع بيانات العروض المسجلة</h3>`,
        });
      }
    };
    fetchPages();
    fetchUsers();
    fetchPosted();
  }, [reload]);

  const removePageClicked = async (item) => {
    const id = item.tweet.id;

    const deleteRes = await deleteFromTable("posted_offers", id, "id = ?");
    if (deleteRes) {
      SwalStatusHandler({ status: deleteRes.status, text: deleteRes.text });
      setReload(!reload);
    }
  };

  useEffect(() => {
    const getPageUser = async () => {
      const sortedPostedOffer = [...postedOffer].sort((a, b) => b.id - a.id);
      const postedOfferMap = sortedPostedOffer.map((tweet) => {
        const postedUserById = users.filter(
          (user) => user.user_id === tweet.userId
        );
        const postedPageById = pages.filter(
          (page) => page.page_id === tweet.pageId
        );
        return { postedUserById, postedPageById, tweet };
      });

      if (postedOffer.length > 0) {
        setPostedOffers(postedOfferMap);
      }
    };

    getPageUser();
  }, [postedOffer]);

  useEffect(() => {
    setTimeout(() => {
      if (postedOffers.length > 0) {
        setLoader(false);
      }
    }, 900);
  }, [postedOffers]);

  return useMemo(() => {
    return (
      <Card.Body dir="rtl" className="px-0 py-2">
        {!loader ? (
          <>
            <Table responsive hover className="table-container">
              <thead>
                <tr>
                  {screenSize > 1080 && <th>الصورة</th>}
                  <th>العنوان</th>
                  <th>الحالة</th>
                  <th>المستخدم</th>
                  <th>اسم الصفحة</th>
                  <th>اسم صفحة التواصل</th>
                  <th> حذف او إعادة </th>
                </tr>
              </thead>

              <tbody>
                {postedOffers.map((item, index) => (
                  <>
                    {item.postedUserById && item.postedUserById.length > 0 && (
                      <tr key={`${item.tweet.id}${index}`}>
                        {screenSize > 1080 && (
                          <>
                            <td>
                              <img
                                style={{ width: "60px", borderRadius: "10%" }}
                                src={item.tweet.offer_image ?? ""}
                                alt="الصورة غير موجودة"
                              />
                            </td>
                          </>
                        )}
                        <td className="d-flex align-items-center justify-content-center ">
                          <h6
                            dir="rtl"
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              width: "250px",
                              textAlign: "center",
                              whiteSpace: "normal", // Allow text to wrap
                              wordWrap: "break-word",
                            }}
                          >
                            {item.tweet.offer_title ?? ""}
                          </h6>
                        </td>
                        <td>
                          <h6
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              backgroundColor: getCurrentState(
                                item.tweet.status
                              ).color,
                              borderRadius: 5,
                              padding: "5px",
                            }}
                            dir="rtl"
                            className=""
                          >
                            {`${getCurrentState(item.tweet.status).status} ${
                              item.tweet.status === 1 &&
                              item.tweet.schedule_time !== null
                                ? item.tweet.schedule_time
                                : ""
                            }`}
                          </h6>

                          {item.tweet.errorMessage && (
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                padding: "5px",
                              }}
                              dir="rtl"
                              // className=""
                            >
                              {`Error: ${item.tweet.errorMessage}`}
                            </p>
                          )}
                        </td>
                        <td>
                          <h6
                            style={{
                              padding: "5px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              textAlign: "center",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            dir="rtl"
                            className=""
                          >
                            {item.postedUserById[0].username}
                          </h6>
                        </td>
                        <td>
                          <h6
                            style={{
                              padding: "5px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              textAlign: "center",
                              whiteSpace: "normal", // Allow text to wrap
                              wordWrap: "break-word",
                            }}
                            dir="rtl"
                            className=""
                          >
                            {item.postedPageById[0].page_name}
                          </h6>
                        </td>
                        <td>
                          <h6
                            style={{
                              padding: "5px",
                              fontSize: "20px",
                              fontWeight: "bold",
                              textAlign: "center",
                              whiteSpace: "normal", // Allow text to wrap
                              wordWrap: "break-word",
                            }}
                            dir="rtl"
                            className=""
                          >
                            {item.postedPageById[0].page_platform}
                          </h6>
                        </td>

                        {item.tweet.status === 3 ? (
                          <td>
                            <Col className="d-flex align-items-center justify-content-center">
                              <Button
                                style={{
                                  margin: "20px",
                                  padding: "10px",
                                  fontSize: "20px",
                                  borderRadius: 13,
                                  fontWeight: "bold",
                                }}
                                onClick={async () => {
                                  const tweetObject = JSON.parse(
                                    item.tweet.requestObject
                                  );
                                  const tweetId = item.tweet.id;
                                  tweetObject.tweetId = tweetId;
                                  // tweetsAPI(JSON.stringify(tweetObject));
                                  const res = await tweetsAPI(
                                    JSON.stringify(tweetObject)
                                  );

                                  if (res.status) {
                                    Swal.fire({
                                      title: "نجاح",
                                      text: res.text,
                                      icon: "success",
                                      confirmButtonText: "حسناً",
                                    });
                                  } else {
                                    Swal.fire({
                                      title: "!خطأ",
                                      text: res.text,
                                      icon: "error",
                                      confirmButtonText: "حسناً",
                                    });
                                  }
                                }}
                              >
                                ارسل الأن
                              </Button>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-x-octagon svg-icon"
                                viewBox="0 0 16 16"
                                color="#f5909e"
                                onClick={() => removePageClicked(item)}
                                style={{
                                  cursor: "pointer",
                                  transition: "fill 0.3s ease",
                                }}
                              >
                                <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                              </svg>
                            </Col>
                          </td>
                        ) : (
                          <td>
                            <Col className="d-flex align-items-center justify-content-center">
                              {item.tweet.status === 1 && (
                                <Button
                                  style={{
                                    margin: "20px",
                                    padding: "10px",
                                    fontSize: "20px",
                                    borderRadius: 13,
                                    fontWeight: "bold",
                                  }}
                                  onClick={async () => {
                                    const tweetObject = JSON.parse(
                                      item.tweet.requestObject
                                    );

                                    const response = await fetch(
                                      posting_api + "cancelJob",
                                      {
                                        method: "POST",
                                        headers: headers,
                                        body: JSON.stringify({
                                          jobId: tweetObject.jobId,
                                        }),
                                      }
                                    );
                                    const res = await response.json();

                                    if (response.ok) {
                                      if (res.status) {
                                        const tweetId = item.tweet.id;
                                        tweetObject.tweetId = tweetId;

                                        const res = await tweetsAPI(
                                          JSON.stringify(tweetObject)
                                        );

                                        if (res.status) {
                                          Swal.fire({
                                            title: "نجاح",
                                            text: res.text,
                                            icon: "success",
                                            confirmButtonText: "حسناً",
                                          });
                                          setReload(!reload);
                                        } else {
                                          Swal.fire({
                                            title: "!خطأ",
                                            text: res.text,
                                            icon: "error",
                                            confirmButtonText: "حسناً",
                                          });
                                        }
                                      } else {
                                        Swal.fire({
                                          title: "!خطأ",
                                          text: res.text,
                                          icon: "error",
                                          confirmButtonText: "حسناً",
                                        });
                                      }
                                    } else {
                                      Swal.fire({
                                        title: "!خطأ",
                                        text: res.text,
                                        icon: "error",
                                        confirmButtonText: "حسناً",
                                      });
                                    }
                                  }}
                                >
                                  ارسل الأن
                                </Button>
                              )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-x-octagon svg-icon"
                                viewBox="0 0 16 16"
                                color="#f5909e"
                                onClick={() => removePageClicked(item)}
                                style={{
                                  cursor: "pointer",
                                  transition: "fill 0.3s ease",
                                }}
                              >
                                <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                              </svg>
                            </Col>
                          </td>
                        )}
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {postedOffer.length === 0 && (
              <h1
                className="d-flex"
                style={{
                  padding: "20px",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                لم يتم ترحيل اي عرض
              </h1>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Card.Body>
    );
  }, [loader, postedOffers, reload]);
};

export default PostedOffer;
