import Swal from "sweetalert2";

const SwalStatusHandler = (options) => {
  const { status, title = false, text, icon = false, timer = false } = options;
  if (timer) {
    Swal.fire({
      icon: icon || status ? "success" : "error",
      title: title ?? "",
      text,
      timer: timer,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  } else {
    Swal.fire({
      icon: icon || status ? "success" : "error",
      title: title ?? "",
      text,
    });
  }
};

export default SwalStatusHandler;
