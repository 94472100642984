import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, InputGroup } from "react-bootstrap";
import { countryArray } from "../../constant/constant";
import Swal from "sweetalert2";
import addToTable from "../../Data/addToTable";
import UserContext from "../../constant/UserContext";
import getFromTable from "../../Data/getFromTable";
import deleteFromTable from "../../Data/deleteFromTable";
import SwalStatusHandler from "../../component/SwalStatusHandler";

const ChangeAppInfo = () => {
  const [consumerKey, setconsumerKey] = useState("");
  const [consumerSecret, setconsumerSecret] = useState("");
  const [platform, setPlatform] = useState(0);
  const [country, setCountry] = useState(0);
  const [showKey, setShowKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const { currentUser } = useContext(UserContext);
  const [apps, setApps] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [showSavedKey, setShowSavedKey] = useState({ id: 0, value: false });
  const [showSavedSecret, setShowSavedSecret] = useState({
    id: 0,
    value: false,
  });

  useEffect(() => {
    const fetchApps = async () => {
      const appsData = await getFromTable("app_configuration");
      if (appsData.status) {
        const parsedData = JSON.parse(appsData.text);
        setApps(parsedData);
      }
    };
    fetchApps();
  }, [platform, refresh]);

  const handelSubmit = async () => {
    if (
      platform !== 0 &&
      country !== 0 &&
      consumerSecret !== "" &&
      consumerKey !== ""
    ) {
      const addStatus = await addToTable(
        "app_configuration",
        [country, platform],
        "country = ? AND 	platform = ?",
        {
          userId: currentUser.user_id,
          consumerKey,
          consumerSecret,
          platform,
          country,
        }
      );

      if (addStatus.status) {
        Swal.fire({
          icon: "success",
          title: "تم التسجيل",
          text: JSON.stringify(addStatus.text),
        });
        setCountry(0);
        setPlatform(0);
        setconsumerKey("");
        setconsumerSecret("");
      }
      if (!addStatus.status) {
        Swal.fire({
          icon: "error",
          title: "خطاء",
          text: addStatus.text,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "خطاء",
        text: "تأكد ان  كل البيانات صحيحة",
      });
    }
  };

  const onPlatformSelected = (e) => {
    setPlatform(e.target.value);
  };
  const onCountrySelected = (e) => {
    setCountry(e.target.value);
  };
  const onKeyChange = (e) => {
    setconsumerKey(e.target.value);
  };
  const onSecretChange = (e) => {
    setconsumerSecret(e.target.value);
  };

  const removePageClicked = async (item) => {
    const id = item.id;
    const deleteRes = await deleteFromTable("app_configuration", id, "id = ?");
    if (deleteRes) {
      SwalStatusHandler({ status: deleteRes.status, text: deleteRes.text });
      setRefresh(!refresh);
    }
  };
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title style={{ fontSize: "25px" }} as="h5">
            تسجيل تطبيق جديد
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h5>{"consumer Key (API key)"}</h5>
                  <InputGroup dir="ltr" className="mb-4">
                    <Form.Control
                      value={consumerKey}
                      onChange={onKeyChange}
                      type={showKey ? "text" : "password"}
                      placeholder="key"
                    />
                    <Button onClick={() => setShowKey(!showKey)}>
                      {!showKey ? "اظهار" : "اخفاء"}
                    </Button>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <h5>{"consumer Secret (API Secret)"}</h5>
                  <InputGroup dir="ltr" className="mb-3">
                    <Form.Control
                      value={consumerSecret}
                      onChange={onSecretChange}
                      type={showSecret ? "text" : "password"}
                      placeholder="Secret"
                    />
                    <Button onClick={() => setShowSecret(!showSecret)}>
                      {!showSecret ? "اظهار" : "اخفاء"}
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Form>
            </Col>
            <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <h5>Platform</h5>
                <Form.Control
                  value={platform}
                  onChange={onPlatformSelected}
                  as="select"
                >
                  <option value={0}>ex. Facebook or Twitter</option>
                  <option value={"Twitter"}>Twitter</option>
                  <option value={"Facebook"}>Facebook</option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlSelect1"
              >
                <h5>Country</h5>
                <Form.Control
                  dir="rtl"
                  value={country}
                  onChange={onCountrySelected}
                  as="select"
                >
                  <option value={0}>اختر البلد</option>
                  {countryArray.map((country) => {
                    if (country.id !== 0) {
                      return (
                        <option value={country.code}>{country.country}</option>
                      );
                    }
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button
            onClick={handelSubmit}
            style={{ fontSize: "20px" }}
            variant="primary"
          >
            سجل التطبيق
          </Button>
        </Card.Body>
      </Card>
      <Row dir="rtl">
        {apps.map((data, index) => {
          return (
            <Col key={`${data.id} ${index}`} md={6} xl={6}>
              <Card>
                <Card.Body className="border-bottom">
                  <div className=" row align-items-center justify-content-center">
                    <div className="row ">
                      <div className=" d-flex col justify-content-center">
                        <i
                          className={`${
                            data.platform === "Twitter"
                              ? "fab fa-twitter text-c-green"
                              : "fab fa-facebook-f text-primary"
                          } f-36 m-10`}
                        />

                        <h3 className="m-10">
                          {data.platform === "Twitter" ? "تويتر" : ""}{" "}
                          {
                            countryArray.find(
                              (country) => country.code === data.country
                            ).country
                          }
                        </h3>
                      </div>
                    </div>

                    <Row>
                      <Col md={10}>
                        <InputGroup dir="ltr" className="m-4">
                          <Form.Control
                            value={data.consumerKey}
                            readOnly
                            type={
                              showSavedKey.id === data.id
                                ? showSavedKey.value
                                  ? "text"
                                  : "password"
                                : "password"
                            }
                            placeholder="consumer Key (API key)"
                          />
                          <Button
                            id={data.id}
                            onClick={(e) => {
                              if (data.id == e.target.id) {
                                setShowSavedKey({
                                  id: data.id,
                                  value: !showSavedKey.value,
                                });
                              }
                            }}
                          >
                            {showSavedKey.id === data.id
                              ? !showSavedKey.value
                                ? "اظهار"
                                : "اخفاء"
                              : "اظهار"}
                          </Button>
                        </InputGroup>

                        <InputGroup dir="ltr" className="m-4">
                          <Form.Control
                            value={data.consumerSecret}
                            readOnly
                            type={
                              showSavedSecret.id === data.id
                                ? showSavedSecret.value
                                  ? "text"
                                  : "password"
                                : "password"
                            }
                            placeholder="consumer Key (API key)"
                          />
                          <Button
                            id={data.id}
                            onClick={(e) => {
                              if (data.id == e.target.id) {
                                setShowSavedSecret({
                                  id: data.id,
                                  value: !showSavedSecret.value,
                                });
                              }
                            }}
                          >
                            {showSavedSecret.id === data.id
                              ? !showSavedSecret.value
                                ? "اظهار"
                                : "اخفاء"
                              : "اظهار"}
                          </Button>
                        </InputGroup>
                      </Col>
                      <Col className="d-flex  justify-content-end align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          color="#f5909e"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          style={{
                            cursor: "pointer",
                            transition: "fill 0.3s ease",
                          }}
                          onClick={() => removePageClicked(data)}
                          className="svg-icon bi bi-trash3-fill"
                        >
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                        </svg>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ChangeAppInfo;
