import { headers, posting_api } from "../constant/constant";

const tweetsAPI = async (postData) => {
  const url = `${posting_api}tweets`;

  const requestInfo = {
    method: "POST",
    headers,
    body: postData, // Convert postData to JSON string
  };

  try {
    const response = await fetch(url, requestInfo);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const res = await response.text();
    return { status: true, text: res };
  } catch (error) {
    return {
      status: false,
      text: "There was a problem with your fetch operation:" + error,
    };
  }
};

export default tweetsAPI;
