import React from "react";
import { Row } from "react-bootstrap";
import { RingLoader } from "react-spinners";

export const Loader = () => {
  return (
    <Row className=" align-items-center  justify-content-center ">
      <RingLoader color="#36d7b7" size={140} />
      <p style={{ fontSize: "20px" }} className="mt-5 ">
        ...
      </p>
    </Row>
  );
};
