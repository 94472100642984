import { MDBCheckbox } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import ScreenWidth from "../../constant/ScreenWidth";
import { createDateFormate } from "../../constant/currentDateFunction";
import { useNavigate } from "react-router-dom";
import { getAlbum } from "../../Data/ImageCall";
import Swal from "sweetalert2";
import { getCurrentState } from "../../constant/constant";

const OffersTable = ({ mapingArray }) => {
  const { screenSize } = useContext(ScreenWidth);
  const [selectedItemId, setSelectedItemId] = useState([]);
  const [dataWithLinks, setDataWithLinks] = useState([]);
  // const [apiStatus, setApiStatus] = useState([]);
  const navigate = useNavigate();
  let selectedIdsArray = selectedItemId;

  const handleRowClick = (e, item) => {
    if (!selectedItemId.includes(item.id)) {
      selectedIdsArray = selectedItemId;
      selectedIdsArray.push(Number(e.currentTarget.id));
    } else {
      selectedIdsArray = selectedIdsArray.filter((id) => id !== item.id);
    }
    setSelectedItemId(selectedIdsArray);
  };

  const handleClick = () => {
    if (selectedItemId.length > 0) {
      navigate("/posting", {
        state: { data: selectedItemId, offers: dataWithLinks },
      });
    } else {
      Swal.fire({
        icon: "warning",
        text: "أختر عرض واحد على الاقل",
      });
    }
  };

  useEffect(() => {
    const updateArrayAsync = async () => {
      const updatedArray =
        (await Promise.all(
          mapingArray.map(async (data) => {
            const links = await getAlbum(data.external);
            if (links) {
              data.links = links;
              return data;
            }
          })
        )) || [];
      setDataWithLinks(updatedArray);
    };
    updateArrayAsync();
  }, [mapingArray]);

  return (
    <Card.Body className="px-0 py-2">
      <Table responsive hover className="table-container">
        <tbody>
          {dataWithLinks.map((item, index) => (
            <tr key={`${item.id} ${index}`}>
              {screenSize > 1080 && (
                <td>
                  <img
                    style={{ width: "100px", borderRadius: "10%" }}
                    src={item.img}
                    alt="activity-user"
                  />
                </td>
              )}
              <td style={{ width: "10px" }}>
                <h6
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "250px",
                    whiteSpace: "normal", // Allow text to wrap
                    wordWrap: "break-word",
                  }}
                >
                  {item.title}
                </h6>
              </td>

              {screenSize > 840 && (
                <>
                  <td>
                    <h6 style={{ fontSize: "20px" }} dir="rtl">
                      <i className="fa fa-circle text-c-green f-10 m-l-10" />
                      {createDateFormate(item.start_at)}
                    </h6>
                  </td>
                </>
              )}

              <td>
                <MDBCheckbox
                  style={{ height: "30px", width: "30px" }}
                  name="flexCheck"
                  id={item.id}
                  label=""
                  value=""
                  onChange={(e) => handleRowClick(e, item)}
                />
              </td>
              {item.status && (
                <td>
                  <h6
                    style={{
                      fontWeight: "bold",
                      backgroundColor: getCurrentState(item.status).color,
                      borderRadius: 5,
                      padding: "5px",
                      paddingLeft: "0px",
                    }}
                    dir="rtl"
                    className=""
                  >
                    {`${getCurrentState(item.status).status}`}
                  </h6>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ padding: "2%", paddingBottom: "2%" }}>
        <Button
          style={{
            fontSize: "26px",
            fontWeight: "bold",
            textShadow: "revert",
          }}
          to="/posting"
          className="label theme-bg text-white f-12"
          onClick={handleClick}
        >
          الذهاب الى صفحة النشر
        </Button>
      </div>
    </Card.Body>
  );
};

export default OffersTable;
