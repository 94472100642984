import { MDBCheckbox } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import ScreenWidth from "../constant/ScreenWidth";
import {
  countryArray,
  posting_api,
  getCurrentState,
} from "../constant/constant";
import { Loader } from "../component/Loader";
// import TwiterIdsProvider from "../constant/TwiterIdsProvider";
import { useApi } from "../provider/ApiProvider";
import updateTables from "../Data/updateTables";
import Swal from "sweetalert2";
import deleteFromTable from "../Data/deleteFromTable";
import SwalStatusHandler from "../component/SwalStatusHandler";
import axios from "axios";

export const Accounts = ({ postingPage, pageNumber }) => {
  const { screenSize } = useContext(ScreenWidth);
  const { state, triggerRefetch } = useApi();
  const social_pages = state.social_pages;
  const pagesData = social_pages.data || [];
  const pagesIsLoading = social_pages.isLoading || false;
  const [country, setCountry] = useState(0);

  const facebookPages = [{ id: 1 }];
  const [twitterPages, setTwiterPages] = useState([]);

  // eslint-disable-next-line
  useEffect(() => {
    const filterPagse = async () => {
      if (pagesData) {
        const twitterPages = await pagesData.filter(
          (page) => page.page_platform === "Twitter"
        );

        if (twitterPages.length > 0) {
          setTwiterPages(await twitterPages);
        }
      }
    };
    filterPagse();
    // eslint-disable-next-line
  }, [pagesIsLoading]);

  const dropDownOnSelect = async (eventKey, event, item) => {
    Swal.fire({
      title: "مراجعة البيانات",
      html: "<h6>انتظر قليلاً للتأكد من هويتك</h6>",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const condtion = `page_id = ${item.page_id}`;
    const foundCountry = countryArray.find(
      // eslint-disable-next-line
      (country) => country.id == event.target.id
    );

    const didUpdate = await updateTables(
      "social_pages",
      {
        page_country: foundCountry.code,
      },
      condtion
    );
    //  { status: false, text: "amr" };

    if (didUpdate.status) {
      triggerRefetch();
      Swal.fire({
        icon: "success",
        html: `<h6>تم تحديث البيانات</h6>`,
        timer: 900,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "خطاء",
        text: `${didUpdate.text} : لم يتم التسجيل`,
      });
    }
  };

  const removePageClicked = async (item) => {
    const name = item.page_name;

    const deleteRes = await deleteFromTable(
      "social_pages",
      name,
      "page_name = ?"
    );

    if (deleteRes) {
      SwalStatusHandler({ status: deleteRes.status, text: deleteRes.text });
      triggerRefetch();
    }
  };

  // Render
  if (pagesIsLoading) {
    return <div className="loaderContainer">{<Loader />}</div>;
  }

  const onCountrySelected = (e) => {
    setCountry(e.target.value);
  };
  return (
    <div>
      <Row dir="rtl">
        <Col>
          <Card className="card-social">
            <Card.Header>
              <Button
                dir="ltr"
                style={{
                  fontSize: "120%",
                  width: "200px",
                  fontWeight: "bold",
                }}
                variant={"info"}
                className="text-capitalize label"
                onClick={() => {
                  if (country != 0) {
                    handleAuthClick(country);
                  } else {
                    Swal.fire({
                      icon: "info",
                      html: `<h3>اختر البلد اولاً</h3>`,
                    });
                  }
                }}
              >
                <i className="fab fa-twitter text-c-gree" />
                Twitter
              </Button>
              <Form.Group
                className="m-3 mb-0"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control
                  dir="rtl"
                  style={{ fontSize: "20px" }}
                  value={country}
                  onChange={onCountrySelected}
                  as="select"
                >
                  <option value={0}>اختر البلد</option>
                  {countryArray.map((country) => {
                    if (country.id !== 0) {
                      return (
                        <option value={country.code}>{country.country}</option>
                      );
                    }
                  })}
                </Form.Control>
              </Form.Group>
            </Card.Header>
            <Card.Body>
              <Table dir="rtl">
                <thead>
                  <tr>
                    <th>اسم الصفحة</th>
                    {screenSize > 840 && <th>وقت التسجيل</th>}
                    <th>بلد الصفحة</th>
                    <th>حذف</th>
                  </tr>
                </thead>
                <tbody>
                  {twitterPages.map((item, index) => (
                    <tr key={`${item.page_id} ${index}`}>
                      <td>
                        <div className="w-100">
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontFamily: "-moz-initial",
                            }}
                            className="mb-1"
                          >
                            {item.page_name}
                          </h6>
                        </div>
                      </td>

                      {screenSize > 840 && (
                        <td>
                          <h6
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontFamily: "fantasy",
                            }}
                            dir="rtl"
                          >
                            {item.creationdate}
                          </h6>
                        </td>
                      )}
                      <td>
                        <Dropdown
                          onSelect={(eventKey, event) =>
                            dropDownOnSelect(eventKey, event, item)
                          }
                        >
                          {/* 'Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger' */}
                          <Dropdown.Toggle
                            variant={!item.page_country ? "info" : "secondary"}
                            id="dropdown-basic"
                            style={{
                              width: "8.5rem",
                              fontSize: "20px",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontFamily: "-moz-initia",
                            }}
                          >
                            {item.page_country
                              ? `${
                                  countryArray.find(
                                    (country) =>
                                      // eslint-disable-next-line
                                      country.code == item.page_country
                                  ).country
                                } `
                              : "اختر البلد"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {/* eslint-disable-next-line*/}
                            {countryArray.map((country) => {
                              if (country.id !== 0) {
                                return (
                                  <Dropdown.Item
                                    style={{
                                      fontSize: "20px",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      fontFamily: "fantasy",
                                    }}
                                    id={country.id}
                                  >
                                    {country.country}
                                  </Dropdown.Item>
                                );
                              }
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="currentColor"
                          class="bi bi-x-octagon"
                          viewBox="0 0 16 16"
                          color="#f5909e"
                          onClick={() => removePageClicked(item)}
                          style={{
                            cursor: "pointer",
                            transition: "fill 0.3s ease",
                          }}
                          className="svg-icon"
                        >
                          <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                          <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                        </svg>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="card-social">
            <Card.Header>
              {!postingPage ? (
                <Button
                  dir="ltr"
                  style={{
                    fontSize: "120%",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                  variant={"primary"}
                  className="text-capitalize label"
                >
                  <i className="fab fa-facebook-f text-c-gree" />
                  Facebook
                </Button>
              ) : (
                <Card
                  dir="ltr"
                  style={{
                    fontSize: "120%",

                    fontWeight: "bold",
                  }}
                  variant={"info"}
                  className="text-capitalize label"
                >
                  Facebook
                </Card>
              )}
            </Card.Header>
            <Card.Body>
              <Table dir="rtl">
                <tbody>
                  {facebookPages.map((item, index) => (
                    <tr key={`${item.id} ${index}`}>
                      <td>
                        <div className="w-100">
                          <h6 className="mb-1">{"Page Name"}</h6>
                        </div>
                      </td>

                      <td>
                        <MDBCheckbox name="flexCheck" label="" value="" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const handleAuthClick = async (countryCode) => {
  try {
    const response = await axios.post(posting_api + "twitter-auth", {
      country: countryCode,
    });
    if (response.status === 200) {
      window.location.href = response.data.redirectTo;
    } else {
      console.error("Network response was not ok.", response);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

// ?state=state&code=X0owQktXRFAxTnN6d3JITkxtcUppa0JoUmVDSU5ZcEYyZ1c1UW45YVZNYVhLOjE3MTA4NzY3MzI2ODE6MTowOmFjOjE
