// ProfileDropdown.js
import React from "react";
import { Dropdown } from "react-bootstrap";

const ProfileDropdown = ({ image, logoutHandle }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-basic">
        <img
          src={image}
          alt="Profile"
          style={{ width: "32px", borderRadius: "50%" }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={logoutHandle}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
