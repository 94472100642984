import { tsawq_api } from "../constant/constant";

const OffersCall = async (pagState, country, type, page, limit) => {
  const url = tsawq_api + "offers";
  const postData = {
    country: country,
    type: type,
    page: page,
    limit: limit,
    pagination_state: pagState,
  };

  const requestInfo = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_TSAWQ_API_KEY,
    },
    body: JSON.stringify(postData),
  };

  try {
    const response = await fetch(url, requestInfo);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
  }
};

export default OffersCall;
