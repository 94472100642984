import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import currentDateFunction, {
  toArabicDigits,
} from "../constant/currentDateFunction";
import { Offers } from "../component/Offers/Offers.jsx";
import RoutingContext from "../constant/RoutingContext.js";
import { Loader } from "../component/Loader.jsx";
import { useApi } from "../provider/ApiProvider.js";
import UserContext from "../constant/UserContext.js";
import updateTables from "../Data/updateTables.js";

export default function Home() {
  const [users, setUsers] = useState([]);
  const { currentRoute } = useContext(RoutingContext);
  const [loader, setLoader] = useState(true);
  const [postedOfferslengthPerDay, setPostedOfferslengthPerDay] = useState(0);
  const [postedOfferslengthPerMonth, setPostedOfferslengthPerMonth] =
    useState(0);
  const { state } = useApi();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const postedOffers = state.posted_offers.data || [];

  const h6Style = {
    color: "#000",
    padding: "5px",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "normal",
    wordWrap: "break-word",
  };
  useEffect(() => {
    const data = state.users.data || [];
    if (currentUser) {
      currentUser.last_login_date = currentDateFunction().dateFormat;
      currentUser.last_login_time =
        currentDateFunction().dayName + " " + currentDateFunction().timeFormat;
      setCurrentUser(currentUser);

      (async () => {
        if (currentUser.user_id) {
          await updateTables(
            "users",
            {
              last_login_date: currentUser.last_login_date,
              last_login_time: currentUser.last_login_time,
            },
            `user_id = ${currentUser.user_id}`
          );
        }
      })();
    }
    setUsers(data);
    const getRequestLength = async () => {
      const currentDate = new Date(); // Get current date
      const currentMonthYear = `${currentDate.getMonth()}-${currentDate.getFullYear()}`;

      const requestsToday = postedOffers.filter((tweet) => {
        return (
          tweet.status === 2 &&
          new Date(tweet.sendDate).toDateString() === currentDate.toDateString()
        );
      });
      const requestsThisMonth = postedOffers.filter((tweet) => {
        const tweetDate = new Date(tweet.sendDate);
        const tweetMonthYear = `${tweetDate.getMonth()}-${tweetDate.getFullYear()}`;
        return tweet.status === 2 && tweetMonthYear === currentMonthYear;
      });
      setPostedOfferslengthPerDay(requestsToday.length);
      setPostedOfferslengthPerMonth(requestsThisMonth.length);
    };

    getRequestLength();

    if (!state.users.isLoading) {
      setLoader(false);
    }
  }, [state]);

  const twiterRequestADay = postedOfferslengthPerDay;
  const twiterRequestAMonth = postedOfferslengthPerMonth;
  const twiterRequestMonthMax = 1500;
  const twiterRequestDayMax = 50;
  const facebookRequest = 0;
  const maxFacebookRequest = 10000;

  // const calculatePresentage = (value, valueMax) => {
  //   const prsentage = (value / valueMax) * 100;
  //   return prsentage.toFixed(1);
  // };

  const dashSalesData = [
    {
      id: 0,
      title: "عدد طلبات تويتر",
      amount: twiterRequestADay,
      maxAmountMonth: twiterRequestMonthMax,
      maxAmountDay: twiterRequestDayMax,
      icon: "fab fa-twitter text-c-green",
      value: twiterRequestAMonth,
      class: "progress-c-theme",
      code: "tw",
    },
    {
      id: 1,
      title: "عدد طلبات فيسبوك",
      amount: facebookRequest,
      maxAmountMonth: maxFacebookRequest,
      maxAmountDay: maxFacebookRequest,
      icon: "fab fa-facebook-f text-primary",
      value: facebookRequest,
      class: "progress-c-theme",
      code: "fa",
    },
  ];

  return (
    <>
      {!loader ? (
        <>
          {currentRoute === 0 && (
            <Row dir="rtl">
              {currentRoute === 0 && (
                <Col>
                  <Row dir="rtl">
                    <Col>
                      <Card className="user-list">
                        <Card.Header>
                          <Card.Title as="h5">سجل المستخدمين</Card.Title>
                        </Card.Header>
                        <Card.Body className="p-0">
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th>المستخدم</th>
                                <th>الصلاحيات</th>
                                <th>الوقت</th>
                                <th>التاريخ</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentUser && (
                                <tr>
                                  <td>
                                    <h6 style={h6Style} className="mb-1">
                                      {currentUser.username}
                                    </h6>
                                  </td>
                                  <td>
                                    <span style={h6Style} className="pie_1">
                                      {currentUser.user_role}
                                    </span>
                                  </td>
                                  <td>
                                    <h6 style={h6Style} className="m-0">
                                      {currentUser.last_login_time}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 style={h6Style} className="m-0">
                                      {currentUser.last_login_date}
                                    </h6>
                                  </td>
                                </tr>
                              )}
                              {users.map((user) => {
                                if (user.user_id !== currentUser.user_id) {
                                  return (
                                    <tr key={user.user_id}>
                                      <td>
                                        <h6 style={h6Style} className="mb-1">
                                          {user.username}
                                        </h6>
                                      </td>
                                      <td>
                                        <span style={h6Style} className="pie_1">
                                          {user.user_role}
                                        </span>
                                      </td>
                                      <td>
                                        <h6 style={h6Style} className="m-0">
                                          {user.last_login_time}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 style={h6Style} className="m-0">
                                          {user.last_login_date}
                                        </h6>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          )}
          {currentRoute === 0 && (
            <Row dir="rtl">
              {dashSalesData.map((data, index) => {
                return (
                  <Col key={`${data.id} ${index}`} md={6} xl={6}>
                    <Card className="card-social">
                      <Card.Body className="border-bottom">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-auto">
                            <i className={`${data.icon} f-36`} />
                          </div>
                          <div className="col text-end">
                            <h3>{toArabicDigits(data.value)}</h3>
                            <h5 className="text-c-green mb-0">
                              <span style={h6Style}>{data.title}</span>
                            </h5>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Body>
                        <div className="row align-items-center justify-content-center card-active">
                          <div className="col-6">
                            <h6 className="text-center m-b-10 f-20">
                              {toArabicDigits(data.amount)}
                              <span dir="ltr" style={h6Style}>
                                طلب اليوم من
                              </span>
                              {toArabicDigits(data.maxAmountDay)}
                            </h6>
                            <div className="progress">
                              <div
                                className="progress-bar progress-c-theme"
                                role="progressbar"
                                style={{
                                  width: `${
                                    (data.amount / data.maxAmountDay) * 100
                                  }%`,
                                  height: "6px",
                                }}
                                aria-valuenow="60"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <h6 className="text-center  mb-3 f-20">
                              {toArabicDigits(data.value)}
                              <span dir="ltr" style={h6Style} className="m-1">
                                : هذا الشهر من
                              </span>
                              {toArabicDigits(data.maxAmountMonth)}
                            </h6>
                            <div className="progress">
                              <div
                                className="progress-bar progress-c-theme2"
                                role="progressbar"
                                style={{
                                  width: `${
                                    (data.value / data.maxAmountMonth) * 100
                                  }%`,
                                  height: "6px",
                                }}
                                aria-valuenow="45"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
          <Offers />
        </>
      ) : (
        <div className="loaderContainer">
          <Loader />
        </div>
      )}
    </>
  );
}

/*
 // <Col key={index} xl={6} xxl={6}>
            //   <Card>
            //     <Card.Body dir="ltr">
            //       <h6 className="mb-4">{data.title}</h6>
            //       <div className="row d-flex align-items-center">
            //         <div className="col-9">
            //           <h3 className="f-w-300 d-flex align-items-center m-b-0">
            //             <i className={`${data.icon} f-30 m-r-15`} />
            //             {data.amount}
            //           </h3>
            //         </div>
            //         <div className="col-3 text-end">
            //           <p className="m-b-0">{data.value}%</p>
            //         </div>
            //       </div>
            //     </Card.Body>
            //     <Card.Body>
            //       <div className="row align-items-center justify-content-center card-active">
            //         <div className="col-6">
            //           <h6 className="text-center  m-b-10">
            //             {`${twiterRequest} / ${twiterRequestDayMax}`}{" "}
            //             <span className="text-muted m-r-5">في اليوم</span>
            //           </h6>
            //           <div className="progress">
            //             <div
            //               className="progress-bar progress-c-theme"
            //               role="progressbar"
            //               style={{
            //                 width: `${calculatePresentage(
            //                   twiterRequest,
            //                   twiterRequestDayMax
            //                 )}%`,
            //                 height: "6px",
            //               }}
            //               aria-valuenow="45"
            //               aria-valuemin="0"
            //               aria-valuemax="100"
            //             />
            //           </div>
            //         </div>
            //         <div className="col-6">
            //           <h6 className="text-center  m-b-10">
            //             {`${twiterRequest} / ${twiterRequestMonthMax}`}{" "}
            //             <span className="text-muted m-r-5">في الشهر</span>
            //           </h6>
            //           <div className="progress">
            //             <div
            //               className="progress-bar progress-c-theme2"
            //               role="progressbar"
            //               style={{
            //                 width: `${calculatePresentage(
            //                   twiterRequest,
            //                   twiterRequestMonthMax
            //                 )}%`,
            //                 height: "6px",
            //               }}
            //               aria-valuenow="45"
            //               aria-valuemin="0"
            //               aria-valuemax="100"
            //             />
            //           </div>
            //         </div>
            //       </div>
            //     </Card.Body>
            //   </Card>
            // </Col>
*/
