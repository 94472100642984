import { posting_api } from "../constant/constant";

const ImageCall = async (externalAlbumId) => {
  try {
    const response = await fetch(posting_api + "album", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        unique: externalAlbumId,
      }),
    });
    const data = await response.json();

    return data.links;
  } catch (error) {
    return error;
  }
};

const getAlbum = async (data) => {
  try {
    const imageAlbum = await ImageCall(data);
    return imageAlbum;
  } catch (error) {
    return false;
  }
};

export { getAlbum };
export default ImageCall;
