import { createContext } from "react";

const UserContext = createContext({
  currentUser: {
    user_id: 1,
    username: "",
    user_role: "",
  },
  setCurrentUser: () => {},
});

export default UserContext;
