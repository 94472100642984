import React, { useContext, useState } from "react";
import { Col, Pagination } from "react-bootstrap";
import ScreenWidth from "../constant/ScreenWidth";

export default function CustomPagination({ metadata, setPageNumber }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { screenSize } = useContext(ScreenWidth);
  const totalPages = metadata.totalPages;
  let startPage = 1;

  const maxPagesNum = screenSize < 780 ? 3 : 10;

  if (currentPage > maxPagesNum) {
    startPage = Math.floor((currentPage - 1) / maxPagesNum) * maxPagesNum;
    if (currentPage % maxPagesNum === 0) {
      startPage += 1; // Correct the startPage when currentPage is exactly divisible by 10
    }
  }

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPageNumber(pageNumber);
  };

  const paginationItems = [];
  for (
    let number = startPage;
    number < Math.min(startPage + maxPagesNum, totalPages + 1);
    number++
  ) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        onClick={() => handlePaginationClick(number)}
        active={number === currentPage}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Col dir="ltr" md={screenSize < 1080 ? 7 : 3.5}>
      <Pagination>
        <Pagination.First onClick={() => handlePaginationClick(1)} />
        <Pagination.Prev
          onClick={() => handlePaginationClick(Math.max(1, currentPage - 1))}
        />
        {paginationItems}
        <Pagination.Next
          onClick={() =>
            handlePaginationClick(Math.min(totalPages, currentPage + 1))
          }
        />
        <Pagination.Last onClick={() => handlePaginationClick(totalPages)} />
      </Pagination>
      <p> عدد الصفحات : {metadata.totalPages}</p>
    </Col>
  );
}
