const countryArray = [
  { id: 0, country: "الكل", code: "all" },
  { id: 1, country: "مصر", code: "eg" },
  { id: 2, country: "السعودية", code: "sa" },
  { id: 3, country: "الكويت", code: "kw" },
  { id: 4, country: "الأمارات", code: "ae" },
  { id: 5, country: "قطر", code: "qa" },
  { id: 6, country: "البحرين", code: "bh" },
  { id: 7, country: "عمان", code: "om" },
];

const posting_api = process.env.REACT_APP_POSTING_API;
const tsawq_api = process.env.REACT_APP_TSAWQ_API;
const headers = {
  "Content-Type": "application/json",
  "x-api-key": process.env.REACT_APP_API_KEY,
};

const textToLinkFormat = (text) => {
  // Replace spaces with dashes
  let linkText = text.replace(/ /g, "-");
  // Encode Arabic characters
  linkText = encodeURIComponent(linkText);
  return linkText;
};

const reformUrl = (url) => {
  // Extract the text after the last '/'
  const textAfterLastSlash = url.substring(url.lastIndexOf("/") + 1);
  const textBeforeLastSlash = url.substring(0, url.lastIndexOf("/"));

  const linkText = textToLinkFormat(textAfterLastSlash);

  const linkGen = textBeforeLastSlash + "/" + linkText;
  return linkGen;
};

const getCurrentState = (state) => {
  switch (state) {
    case 1:
      return { color: "#FEFF99", status: "قيد الانتظار" };
    case 2:
      return { color: "#CBEADC", status: "تم الارسال" };
    case 3:
      return { color: "#F4D0D1", status: "فشل" };
    default:
      break;
  }
};

export {
  countryArray,
  posting_api,
  tsawq_api,
  reformUrl,
  headers,
  getCurrentState,
};
