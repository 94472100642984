export const tweetObject = {
  pageId: [],
  userId: "",
  offerId: "",
  message: "",
  link: "",
  imageURL: [],
  offer_image: "",
  offer_title: "",
};
