import { headers, posting_api } from "../constant/constant";

const loginCall = async (username, password) => {
  let url = `${posting_api}login`;

  let request = {
    method: "POST",
    headers,
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  };

  const response = await fetch(url, request);

  if (!response.ok) {
    return false;
  }

  const loginResponse = await response.json();
  return loginResponse;
};

export default loginCall;
