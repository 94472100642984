import React, { useContext, useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { Button } from "react-bootstrap";
import RoutingContext from "../constant/RoutingContext";
import { useNavigate } from "react-router-dom";
import ScreenWidth from "../constant/ScreenWidth";
import { logo } from "../constant/Images";
import ProfileDropdown from "./ProfileDropdown";
import AuthorisationContext from "../constant/AuthorisationContext";

export default function Navbar() {
  const [openNavRight, setOpenNavRight] = useState(false);
  const { setRoutIndex } = useContext(RoutingContext);
  const { setAuthorisationStatus } = useContext(AuthorisationContext);
  const { screenSize } = useContext(ScreenWidth);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
    setRoutIndex(0);
  };
  const handleSettingsClick = () => {
    navigate("/settings");
    setRoutIndex(0);
  };
  const handleAccountsClick = () => {
    navigate("/accounts");
    setRoutIndex(0);
  };
  const handleOffersClick = () => {
    navigate("/");
    setRoutIndex(1);
  };
  const handlePostedOffersClick = () => {
    navigate("/posted-offer");
    setRoutIndex(0);
  };

  return (
    <MDBNavbar
      dir="rtl"
      style={{
        minHeight: screenSize <= 991 ? "30px" : "80px",
        paddingTop: "1%",
      }}
      expand="lg"
      light
      bgColor="light"
    >
      <MDBContainer fluid>
        <MDBNavbarToggler
          type="button"
          data-target="#navbarRightAlignExample"
          aria-controls="navbarRightAlignExample"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenNavRight(!openNavRight)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openNavRight}>
          <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
            <MDBNavbarItem>
              <ProfileDropdown
                image={logo}
                logoutHandle={() => {
                  setRoutIndex(0);
                  setAuthorisationStatus(false);
                  localStorage.setItem("auth", JSON.stringify(false));
                  navigate("/login");
                }}
              />
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Button
                style={{ fontSize: "23px", maxWidth: "250px" }}
                className="text-capitalize label"
                variant={"secondary"}
                onClick={handleHomeClick}
              >
                الصفحة الرئيسية
              </Button>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <Button
                key={0}
                className="text-capitalize label "
                variant={"secondary"}
                onClick={handleOffersClick}
                style={{ fontSize: "23px", maxWidth: "250px" }}
              >
                كل العروض
              </Button>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Button
                style={{ fontSize: "23px", maxWidth: "250px" }}
                className="text-capitalize label"
                variant={"secondary"}
                onClick={handlePostedOffersClick}
              >
                عروض تم ترحيلها
              </Button>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Button
                style={{ fontSize: "23px", maxWidth: "250px" }}
                className="text-capitalize label"
                variant={"secondary"}
                onClick={handleAccountsClick}
              >
                الحسابات
              </Button>
            </MDBNavbarItem>
            {/* {currentUser.user_role === "admin" && ( */}
            <MDBNavbarItem>
              <Button
                style={{ fontSize: "23px", maxWidth: "250px" }}
                className="text-capitalize label"
                variant={"secondary"}
                onClick={handleSettingsClick}
              >
                الإعدادات
              </Button>
            </MDBNavbarItem>
            {/* )} */}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}

//  <Col>
//    <Card>
//      <Card.Body>
//        <Button
//          key={0}
//          className="btn-square text-capitalize w-100"
//          variant={"secondary"}
//          onClick={handelAllOffers}
//          style={{ fontSize: "110%" }}
//        >
//          كل العروض
//        </Button>
//        <Button
//          key={0}
//          className="btn-square text-capitalize w-100"
//          variant={"secondary"}
//          style={{ fontSize: "110%" }}
//        >
//          عروض تم ترحيلها
//        </Button>
//        <Button
//          key={0}
//          className="btn-square text-capitalize w-100"
//          variant={"secondary"}
//          style={{ fontSize: "110%" }}
//        >
//          الحسابات
//        </Button>
//        <Button
//          key={0}
//          className="btn-square text-capitalize w-100"
//          variant={"secondary"}
//          style={{ fontSize: "110%" }}
//        >
//          الاعدادات
//        </Button>
//      </Card.Body>
//    </Card>
//  </Col>;
